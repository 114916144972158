@import 'src/app/theme/styles/components/_colors.scss';

.p-overlaypanel:has(.guests-panel):before,
.p-overlaypanel:has(.guests-panel):after {
  display: none;
}

.continue-button .p-button-label {
  color: #416dde;
  font-family: Asap;
  font-size: 20px;
  font-weight: 600;
  line-height: 20px;
}

.clear-button .p-button-label {
  background-color: #fff;
  color: #393a53;
  font-family: Inter, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  border: 0;
}

.custom-modal {
  .guests-panel {
    border-radius: 10px;
    width: 100%;

    &-hotel,
    &-restaurant {
      max-width: 300px;
    }

    &-passengers {
      max-width: 361px;
    }

    &-restaurant {
      max-width: 300px;
    }
  }

  @media (max-width: 991px) {
    align-items: center !important;
  }

  .timepicker-modal {
    overflow: initial;
    background: #fff;
    max-width: 252px;
    width: 100%;

    @media (max-width: 991px) {
      max-width: 100%;
      border-radius: 20px;
      overflow: hidden;
    }

    .custom-modal-header {
      border-bottom: 1px solid #d5d6e2;
      height: 40px;

      span {
        font-size: 20px;
        font-weight: 700;
        line-height: 20px;
        color: #515478;
      }
    }

    .custom-modal-content {
      background: #fff;
      padding: 22px 26px;
      height: 380px;

      .time-buttons {
        overflow-y: scroll;

        .p-button {
          height: 40px;
          color: #000;
          background-color: #fff;
          text-align: center;
          font-size: 16px;
          font-weight: 700;
          line-height: 20px;
          border: 0;
          transition: none;

          &.selected {
            color: #fff;
            background-color: $primary-color;
          }

          &:focus,
          &:active {
            border: 0;
            outline: none;
            box-shadow: none;
          }
        }
      }
    }

    .custom-modal-footer {
      background: #fff;
      border-top: 1px solid #d5d6e2;
      padding: 9.5px 16px;

      .p-button {
        height: 36px;
        font-size: 14px;
        font-weight: 700;
        line-height: 20px;
        background-color: $primary-color;
        width: 132px;
      }
    }
  }
}

.guests-panel-hotel {
  background-color: #fcfcfd;
  border: 1px solid $border-gray;

  .p-inputnumber {
    width: 122px;
    height: 40px;
  }

  .delete-room-button button {
    color: #e01e5a;
    text-align: right;
    font-size: 12px;
    font-weight: 700;
    line-height: 18px;
    background: #fff;
    padding: 0;
    border: 0;
  }

  .p-inputnumber,
  .p-dropdown {
    max-width: 122px !important;
    width: 100% !important;
  }
}

.p-overlaypanel:has(.guests-panel-restaurant) {
  .p-inputnumber {
    width: 100% !important;
  }
}

.r-destination-list.mat-select-panel {
  position: absolute;
  top: 60px;
  left: -38px;
}

.r-destination-list {
  @media (min-width: 992px) {
    padding: 8px !important;
  }

  .mat-option.mat-selected:not(.mat-option-multiple) {
    background: #fff;
  }

  &.mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple),
  .mat-option.mat-active {
    background: #fff;
  }

  .r-destination-item {
    .r-destination-item-name {
      margin-left: 8px;
    }

    &:hover {
      color: #fff;
      background: $primary-color !important;

      .mat-option-text,
      svg {
        color: #fff;
      }
    }
  }

  .mat-option-text,
  .mat-option {
    border-radius: 8px;
    color: #515478;
    padding: 8px;
  }
}
