
.widget-container {
    padding: 30px;
    background-color: white;
    box-shadow: 0 12px 24px rgba(17, 67, 123, 0.12);
    border-radius: 8px;
    cursor: pointer;

    margin-bottom: 1.5rem;
    
}