// theme colors
$primary-color: #3b72b0;

$secondary-color: #ffcc29;

$title: #231f20;
$paragraph: #231f20;
$link: #0d4494;
$cornflower-blue: #22222f;

$profile-primary-color: #5a60b0;
$profile-secondary-color: #c7c8cf;
$border-gray: #d5d6e2;
$dusty-blue-gray: #b0b2c9;
$dusty-blue-gray-dark: #8588ab;
$background-blue: #416dde;
$gold: #ffcc04;

$background-primary: #ffffff !default;
$background-secondary: #edf0fa !default;

$header-footer-color: #fff;

:root {
  --primary-background: #{$background-primary};
  --secondary-background: #{$background-secondary};
  --header-footer-background: #{$header-footer-color};
}

// [data-theme="dark"]:root {
//   --primary-background: #ede9e8;
//   --secondary-background: #ede9e8;
// }

//label colors
$active-color: #69c98c;
$pending-color: #fccc3d;
$inactive-color: #858495;
$draft-color: #2f9df4;
$archived-color: #e6536c;
$inprogress-color: #236bb4;

$steel-blue-color: #8588ab;

$bkg-active-color: rgba(105, 201, 140, 0.2);
$bkg-pending-color: rgba(252, 204, 61, 0.2);
$bkg-inactive-color: rgba(133, 132, 149, 0.2);
$bkg-draft-color: rgba(47, 157, 244, 0.2);
$bkg-archived-color: rgba(230, 83, 108, 0.2);
$bkg-inprogress-color: rgba(73, 80, 250, 0.2);

$like-color: #2ccea7;
$dislike-color: #ea4335;

//label buttons active/inactive/disabled

$active-btn-color: #34a853;
$inactive-btn-color: #848484;
$disabled-btn-color: #e64335;

.positive-amount {
  color: rgba(105, 201, 140, 1) !important;
}

.negative-amount {
  color: rgba(241, 56, 88, 1) !important;
}
