@import '/src/app/theme/styles/components/colors';

.filterRow {
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 49.49%, rgba(36, 57, 138, 0.08) 100%), #FFFFFF;
  padding: 12px;
  border-radius: 8px 8px 0px 0px;
}
// Bogdanel: am adaugat clasa .filterRow mai jos, pt ca afecta stilizarile in tot proiectul, nu doar unde este directiva
.filterRow .mat-form-field {
  width: 100%;
}

.tableContent {
  background: #FFFFFF;
  border-top: 1px solid rgba(36, 57, 138, 0.1);
  padding: 12px;
  border-radius: 0px 0px 8px 8px;
}

 .mat-header-cell {
    font-weight: 400;
    font-size:16px !important;
    line-height: 20px !important;
    color: $primary-color!important;
    //align-items: center;
}

 .mat-table td {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}

 .mat-header-cell {
  border-bottom-color: $primary-color !important;
}

//.mat-header-cell.actions-class{
//  justify-content: flex-end;
//  text-align: right;
//}

.mat-column-actions > div{
  justify-content: flex-end;
}

//th.mat-header-cell .mat-sort-header-container {
//  justify-content: center;
//}
//th.mat-header-cell.mat-column-title .mat-sort-header-container {
//  justify-content: start;
//}

//th.mat-header-cell,
//td.mat-cell {
//  text-align: center;
//}


th.mat-header-cell.mat-column-actions{
  text-align: right;
}

td.mat-cell.mat-column-title{
  text-align: left !important;
}

td.mat-cell{
  color: #000000!important;
    font-weight: 400;
    font-size:16px;
    line-height: 20px;
    align-items: center;
}

td.mat-column-title {
  font-weight: 700 !important;
  color: $primary-color!important;
  cursor: pointer;
}

td.mat-column-title:hover {
  color: #000000!important;
}


@media only screen and (min-width:991px) and (max-width: 1199px){
  .editorals-table-class td.mat-cell.cdk-cell{
    color: #000000!important;
      font-weight: 300;
      font-size:16px;
      line-height: 23.44px;
      align-items: center;
      padding: 0 10px;
  }
}


// @media (max-width: 768px) {
  @media (max-width: 991px) {
  .mat-table[appMatTableResponsive] {
       .mat-header-row {
      //display: flex;
      flex-wrap: wrap;
      height: auto;
      position: sticky;
      top: 0;
      z-index: 2;
      display: none;

      th.mat-header-cell {
        display: flex;
        flex: 1;
        align-items: center;
        padding: 1rem 0.5rem;
        justify-content: space-around;

        & > .mat-sort-header-container {
          padding-left: 15px; // padding is used to center the header table cells, due to the width of the sort icon
        }

        &:not([mat-sort-header]),
        &.mat-sort-header-disabled,
        &.mat-column-actions {
          display: none;
        }
      }
    }

    .mat-row {
      display: block;
      height: auto;
      margin-top: 50px;

      td.mat-cell:not(.mat-column-actions) {
        display: grid;
        grid-template-columns: 50% 50%;
        padding: 0.5rem;
        text-align: right;
        word-break: break-word;

        & > * {
          grid-column: 2;
        }

        &:before {
          content: attr(data-column-name);
          text-align: left;
        }
      }

      td.mat-column-actions {
        display: flex;
        justify-content: center;
        padding: 0 0.5rem;

        & > button {
          margin: 0.5rem 0;
        }
      }

      td.mat-column-status {
        display: flex !important;
        justify-content: space-between !important;
      }

      &:last-of-type td:last-of-type {
        border: none;
      }

      //&:not(:last-of-type) td:last-of-type {
      //  border-bottom: 0.5rem solid #fafafa;
      //}
    }
  }

  .mat-card,
  .mat-expansion-panel {
    .mat-table[matTableResponsive] {
      border: 0.5rem solid #fafafa;
    }
  }

  .d-flex.actions-container{
    justify-content: center;
    gap: 0.5rem;
  }
}
