@mixin menu-level-padding($direction){
    $elem: '';
    @for $i from 2 through 10 {
        $elem: if($i == 2, ".sub-menu", selector-nest($elem, ".sub-menu"));
        #{$elem + ' .mat-button'} {
            @if $direction == "rtl" {
                padding-right: 16px + (20px * $i);
            }
            @else{
                padding-left: 16px + (20px * $i);
            }
        }
    }
}

@mixin fontSize($size: 1px) {
    @for $i from 5 through 100 {
        .fs-#{$i} {
            font-size: $i+px  !important;
        }
    }
}
