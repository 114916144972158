/* You can add global styles to this file, and also import other style files */

@import 'primeng/resources/themes/lara-light-blue/theme.css';
@import 'primeng/resources/primeng.css';

@import 'src/app/theme/styles/_mixins.scss';

@include fontSize(1px);

@font-face {
  font-family: 'Avenir';
  src: url('../src/assets/fonts/font-awesome/webfonts/avenir-black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Avenir';
  src: url('../src/assets/fonts/font-awesome/webfonts/Avenir-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Avenir';
  src: url('../src/assets/fonts/font-awesome/webfonts/AvenirNextCyr-Demi.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Avenir';
  src: url('../src/assets/fonts/font-awesome/webfonts/Avenir Heavy.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Asap';
  src: url('../src/assets/fonts/font-awesome/webfonts/Asap-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Asap';
  src: url('../src/assets/fonts/font-awesome/webfonts/Asap-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Asap';
  src: url('../src/assets/fonts/font-awesome/webfonts/Asap-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Asap';
  src: url('../src/assets/fonts/font-awesome/webfonts/Asap-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Asap';
  src: url('../src/assets/fonts/font-awesome/webfonts/Asap-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src:
    local('Inter'),
    url('/assets/fonts/Inter-Medium.woff2') format('woff2');

  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src:
    local('Inter'),
    url('/assets/fonts/Inter-SemiBold.woff2') format('woff2');
  font-weight: 600;
  font-style: normal;
}

@import '../node_modules/primeng/resources/themes/lara-light-blue/theme.css';
@import '../node_modules/primeng/resources/primeng.css';

@import 'app/theme/styles/spinner';
@import '@angular/material/prebuilt-themes/indigo-pink.css';
@import 'app/theme/styles/base';
@import 'app/theme/styles/spaces';
@import 'app/theme/styles/theme';
@import 'app/theme/styles/rtl';
@import 'app/theme/styles/_primeng-tweaks.scss';
@import 'primeicons/primeicons.css';

// import our custom components styles
@import 'app/theme/styles/components/components.scss';

// font awesome
@import 'assets/fonts/font-awesome/css/all.css';

/* Default theme. ~960B */
@import '~@vime/core/themes/default.css';

/* Optional light theme (extends default). ~400B */
@import '~@vime/core/themes/light.css';

//owl carousel
@import 'ngx-owl-carousel-o/lib/styles/scss/owl.carousel';
@import 'ngx-owl-carousel-o/lib/styles/scss/owl.theme.default';

input[type='button'] {
  touch-action: manipulation;
}

input,
select {
  font-size: 100% !important;
}

ngx-intl-tel-input input {
  height: 47.5px;
  margin-bottom: 20px;
  padding: 10px;
  border: 1px solid #d9dee4;
  border-radius: 4px;
  font-size: 18px;
  padding-top: 11px !important;
}

ngx-intl-tel-input.ng-invalid.ng-touched input {
  border: 1px solid #c0392b;
}

ngx-intl-tel-input input:hover {
  border-color: #000;
  box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.24);
}

ngx-intl-tel-input input:focus {
  outline: none !important;
  border-color: #3498db;
  box-shadow: 0 0 0 0 #000;
}

ngx-intl-tel-input input::-webkit-input-placeholder {
  color: #bac2c7;
}

ngx-intl-tel-input input:-ms-input-placeholder {
  color: #bac2c7;
}

ngx-intl-tel-input input::-ms-input-placeholder {
  color: #bac2c7;
}

ngx-intl-tel-input input::placeholder {
  color: #bac2c7;
}

@media screen and (max-width: 991px) {
  ::ng-deep ngx-intl-tel-input {

    input,
    select {
      font-size: 100% !important;
    }
  }
}

@media screen and (max-width: 991px) {
  body {
    font-size: 16px !important;
  }
}

* {
  touch-action: manipulation !important;
}

/*** iPhone and iOS Form Input Zoom Fixes ***/
/* Fix Input Zoom on devices older than iPhone 5: */
@media screen and (device-aspect-ratio: 2/3) {

  select,
  textarea,
  input[type='text'],
  input[type='password'],
  input[type='datetime'],
  input[type='datetime-local'],
  input[type='date'],
  input[type='month'],
  input[type='time'],
  input[type='week'],
  input[type='number'],
  input[type='email'],
  input[type='url'] {
    font-size: 16px !important;
  }
}

/* Fix Input Zoom on iPhone 5, 5C, 5S, iPod Touch 5g */
@media screen and (device-aspect-ratio: 40/71) {

  select,
  textarea,
  input[type='text'],
  input[type='password'],
  input[type='datetime'],
  input[type='datetime-local'],
  input[type='date'],
  input[type='month'],
  input[type='time'],
  input[type='week'],
  input[type='number'],
  input[type='email'],
  input[type='url'] {
    font-size: 16px !important;
  }
}

/* Fix Input Zoom on iPhone 6, iPhone 6s, iPhone 7  */
@media screen and (device-aspect-ratio: 375/667) {

  select,
  textarea,
  input[type='text'],
  input[type='password'],
  input[type='datetime'],
  input[type='datetime-local'],
  input[type='date'],
  input[type='month'],
  input[type='time'],
  input[type='week'],
  input[type='number'],
  input[type='email'],
  input[type='tel'],
  input[type='url'] {
    font-size: 16px !important;
  }
}

/* Fix Input Zoom on iPhone 6 Plus, iPhone 6s Plus, iPhone 7 Plus, iPhone 8, iPhone X, XS, XS Max  */
@media screen and (device-aspect-ratio: 9/16) {

  select,
  textarea,
  input[type='text'],
  input[type='password'],
  input[type='datetime'],
  input[type='datetime-local'],
  input[type='date'],
  input[type='month'],
  input[type='time'],
  input[type='week'],
  input[type='number'],
  input[type='email'],
  input[type='tel'],
  input[type='url'] {
    font-size: 16px !important;
  }
}

::ng-deep {

  .cdk-global-overlay-wrapper,
  .cdk-overlay-container {
    z-index: 9999 !important;
  }
}

* {
  font-family: Inter, sans-serif;
  font-weight: 500;

  @media screen and (max-width: 991px) {
    font-family: 'Asap', Helvetica, sans-serif;
    font-weight: 400;
  }
}

a.message-link {
  color: #fff;
  text-decoration: underline;
  transition: 0.3s;
}

a.message-link:hover {
  color: #7b7b7b;
}

.logo-text-white {
  font-family: Inter, sans-serif;
  font-size: 32px;
  text-decoration: none;
  font-weight: 900;
  color: white;
}

.logo-text-blue {
  font-family: Inter, sans-serif;
  font-size: 32px;
  text-decoration: none;
  color: hsla(233, 83%, 60%, 1);
  margin-bottom: -4px;
  font-weight: 900;
}

.h1-white {
  color: white;
  font-size: 72px;
  font-weight: 900;
}

.h3-black {
  color: $primary-color;
  font-size: 30px;
  margin: unset;
  font-weight: 900;
}

@media (max-width: 991px) {
  .h1-white {
    color: white;
    font-size: 36px;
    font-weight: 900;
  }
}

.pac-container {
  box-shadow: unset !important;
}

@media screen and (max-width: 991px) {
  .hide-time-selector {
    .p-timepicker {
      display: none !important;
    }
  }

  .pac-container {
    border: none;
    background: transparent;
    width: calc(100% - 90px) !important;
    max-width: 480px !important;
    position: fixed !important;
    box-shadow: unset !important;
    left: calc(50% - calc(100% - 70px) / 2) !important;
    display: none !important;

    @media screen and (max-width: 991px) {
      left: calc(69% - (100% - 51px) / 2) !important;
    }

    @media screen and (max-width: 850px) {
      width: 450px !important;
      left: calc(62% - (100% - 140px) / 2) !important;
    }

    @media screen and (max-width: 800px) {
      left: calc(59% - (100% - 140px) / 2) !important;
    }

    @media screen and (max-width: 750px) {
      left: calc(57% - (100% - 140px) / 2) !important;
      width: calc(100% - 75px) !important;
    }

    @media screen and (max-width: 700px) {
      left: calc(54% - (100% - 140px) / 2) !important;
    }

    @media screen and (max-width: 650px) {
      left: calc(50% - (100% - 140px) / 2) !important;
    }

    @media screen and (max-width: 600px) {
      left: calc(45% - (100% - 140px) / 2) !important;
    }

    @media screen and (max-width: 550px) {
      left: calc(45% - (100% - 140px) / 2) !important;
    }

    @media screen and (max-width: 500px) {
      width: calc(100% - 70px) !important;
      //left: calc(45% - (100% - 140px) / 2) !important
      left: 30px !important;
      background: white;
      max-height: 200px;
      overflow: auto;
      top: 260px !important;
    }

    top: 310px !important;

    .pac-item {
      border: none;
      height: 44px !important;
      display: flex;
      align-items: center;

      .pac-item-query {
        font-size: 16px !important;
        font-family: Asap, 'sans-serif' !important;

        span {
          font-size: 16px !important;
          font-family: Asap, 'sans-serif' !important;
        }
      }
    }
  }
}

.pac-logo::after {
  display: none;
}

.width-100 {
  width: calc(100% - 32px);
}

.width-50 {
  width: 50%;
}

.width-40 {
  width: 40%;
}

.width-60 {
  width: 60%;
}

.width-33 {
  width: 33.33%;
}

.width-70 {
  width: 70%;
}

.width-30 {
  width: 30%;
}

.strike-through {
  text-decoration: line-through;
}

.padding-5 {
  padding: 5px;
}

.flex {
  display: flex;
}

// truncate strings in long  strings
.parent-truncate {
  display: table;
  table-layout: fixed;
  width: 100%;
}

.child-truncate {
  display: table-cell;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.s16 {
  font-size: 1rem;
}

.s20 {
  font-size: 1.25rem;
}

.s30 {
  font-size: 1.875rem;
}

.s14 {
  font-size: 0.875rem;
}

.s-24 {
  font-size: 1.5rem;
}

.s12 {
  font-size: 0.75rem;
}

.s18 {
  font-size: 1.125rem;
}

.s24 {
  font-size: 1.5rem;
}

.height-60 {
  height: 60%;
}

.height-40 {
  height: 54%;
}

.gap-2 {
  gap: 2rem;
}

.gap-4px {
  gap: 0.25rem;
}

.text-align-center {
  text-align: center;
}

.text-align-end {
  text-align: end;
}

.color-black {
  color: black;
}

.color-white {
  color: white;
}

.color-primary {
  color: $primary-color;
}

.ellipsis {
  /* Display text in a single line */
  white-space: nowrap;
  /* Add ellipsis to overflow text */
  overflow: hidden;
  text-overflow: ellipsis;
}

.max-width-wrapper {
  max-width: 1120px !important;
  margin-left: auto !important;
  margin-right: auto !important;

  @media (max-width: 640px) {
    padding: 0 16px;
  }

  &__percentage {
    max-width: 1120px !important;
    margin-left: auto !important;
    margin-right: auto !important;

    @media (max-width: 991px) {
      width: 90%;
    }
  }
}

.max-width-wrapper .max-width {
  max-width: 1312px !important;
}

.background-white {
  background-color: white;
}

.bst-grey {
  color: #8e96d4;
}

.bst-grey-85 {
  color: #515478;
}

.bst-gold {
  color: #cda840;
}

.bst-blue {
  color: $primary-color;
}

.bst-green {
  color: #1aff00;
}

.bst-pink {
  color: #fda29b;
}

.bst-white {
  color: white;
}

.bst-black {
  color: #000000;
}

.bst-black-op {
  color: rgba(34, 34, 47, 0.85);
}

.w-33 {
  width: 33% !important;
}

.phone-class {
  height: 28px !important;
  font-size: 14px !important;
  font-family: Inter, sans-serif !important;
}

app-flight-voucher {
  @media screen and (min-width: 992px) {
    .phone-class {
      padding-top: 20px !important;
      height: 28px !important;
      font-size: 14px !important;
      font-family: 'Avenir', Helvetica, sans-serif !important;
    }
  }
}

.separate-dial-code {
  margin-bottom: 0px !important;
}

@media (max-width: 991px) {
  .charade {
    color: #22222f;
  }

  .extra-large-title {
    font-family: Asap, 'sans-serif';
    font-size: 24px !important;
    font-weight: 600 !important;
    line-height: 24px;
  }

  .large-title {
    font-family: Asap, 'sans-serif';
    font-size: 22px !important;
    font-weight: 600 !important;
    line-height: 24px;
  }

  .medium-title {
    font-family: Asap, 'sans-serif';
    font-size: 20px !important;
    font-weight: 600 !important;
    line-height: 24px;
  }

  .small-title {
    font-family: Asap, 'sans-serif';
    font-size: 18px !important;
    font-weight: 400 !important;
    line-height: 28px;
  }

  .description-title {
    font-family: Asap, 'sans-serif';
    font-size: 16px !important;
    font-weight: 400 !important;
    line-height: 28px;
  }

  .normal-description {
    font-family: Asap, 'sans-serif';
    font-size: 14px !important;
    font-weight: 400 !important;
    line-height: 20px;
  }

  .normal-description-500 {
    font-family: Asap, 'sans-serif';
    font-size: 14px !important;
    font-weight: 500 !important;
    line-height: 20px;
  }

  .small-description {
    font-family: Asap, 'sans-serif';
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
  }

  .options-list--included {
    font-family: Asap, sans-serif;
    font-size: 12px;
    font-weight: 600;
    line-height: 24px;
  }

  .options-list--not-included {
    font-family: Asap, sans-serif;
    font-size: 12px;
    font-weight: 400;
    line-height: 24px;
  }

  h2 {
    color: #22222f;
    font-family: Asap, 'sans-serif';
  }

  h3 {
    color: #22222f;
    font-family: Asap, 'sans-serif';
  }

  h4 {
    color: #22222f !important;
    // font-family: Asap, 'sans-serif';
    font-family: Inter;
    font-size: 18px;
    font-weight: 700;
    line-height: 32px;
    letter-spacing: 0.02em;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
  }

  .baggage-type {
    font-family: Asap, 'sans-serif';
  }

  .baggage-details {
    font-family: Asap, 'sans-serif';
  }
}

.asap-light {
  font-family: Asap, sans-serif;
  font-weight: 300 !important;
}

.asap-medium {
  font-family: Asap, sans-serif;
  font-weight: 500 !important;
}

.asap-regular {
  font-family: Asap, sans-serif;
  font-weight: 400 !important;
}

.asap-semiBold {
  font-family: Asap, sans-serif;
  font-weight: 600 !important;
}

.asap-bold {
  font-family: Asap, sans-serif;
  font-weight: 700 !important;
}

.asap-semi-bold {
  font-family: Asap, sans-serif;
  font-weight: 600;
}

.inter-semibold {
  font-family: Inter, sans-serif;
  font-weight: 600 !important;
}

.inter-regular {
  font-family: Inter, sans-serif;
  font-weight: 400 !important;
}

@media screen and (max-width: 991px) {
  .mat-select-panel-wrap {
    display: flex;
    justify-content: flex-end;

    .mat-select-panel.custom-dropdown {
      width: 100% !important;
      max-width: 100% !important;
      min-width: 100% !important;
    }

    .select-gender-panel {
      position: absolute;
      left: 74px;
      top: 54px;
      max-width: 100%;
      width: calc(100% - 70px);
    }

    .select-gender-panel-checkout {
      position: absolute;
      left: 54px;
      top: 84px;
      max-width: 100%;
      width: calc(100% - 64px);
    }
  }

  .charade {
    color: #22222f;
  }

  .form-title {
    font-family: Asap, 'sans-serif';
    font-size: 20px !important;
    font-weight: 600 !important;
    line-height: 24px;
  }

  .description-title {
    font-family: Asap, 'sans-serif';
    font-size: 18px !important;
    font-weight: 400 !important;
    line-height: 28px;
  }

  .small-title {
    font-family: Asap, 'sans-serif';
    font-size: 16px !important;
    font-weight: 400 !important;
    line-height: 28px;
  }

  .normal-description {
    font-family: Asap, 'sans-serif';
    font-size: 14px !important;
    font-weight: 400 !important;
    line-height: 20px;
  }

  .small-description {
    font-family: Asap, 'sans-serif';
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
  }

  .options-list--included {
    font-family: Asap, sans-serif;
    font-size: 12px;
    font-weight: 600;
    line-height: 24px;
  }

  .options-list--not-included {
    font-family: Asap, sans-serif;
    font-size: 12px;
    font-weight: 400;
    line-height: 24px;
  }

  h2 {
    color: #22222f;
    font-family: Asap, 'sans-serif';
  }

  h3 {
    color: #22222f;
    font-family: Asap, 'sans-serif';
  }

  h4 {
    color: #22222f;
    font-family: Asap, 'sans-serif';
  }

  .baggage-type {
    font-family: Asap, 'sans-serif';
  }

  .baggage-details {
    font-family: Asap, 'sans-serif';
  }

  .toolbar-1 {
    .cdk-overlay-pane:not(.auth-modal):not(.enrollment-modal) {
      width: 100% !important;

      @media screen and (max-width: 991px) {
        max-width: 100vw !important;
        display: flex;
        justify-content: center;
      }

      @media screen and (max-width: 800px) {
        max-width: 60vw; //plane destination modal on tablet fix
      }

      @media screen and (max-width: 700px) {
        max-width: 90vw; //plane destination modal on tablet fix
      }

      @media screen and (max-width: 570px) {
        max-width: 90vw; //plane destination modal on tablet fix
      }
    }

    .cdk-overlay-dark-backdrop {
      background: rgba(65, 109, 222, 0.75);
      backdrop-filter: blur(4px);
    }
  }

  .ng-trigger.p-overlaypanel {
    position: fixed;
    width: 100vw;
    height: 100vh;
    background: transparent;
    padding-top: 0;
    top: 0 !important;
    display: flex !important;
    align-content: center;
    justify-content: center;
    align-items: center;
    left: 0 !important;
    transform: initial !important;

    &:before {
      content: '' !important;
      width: 100vw !important;
      height: 100vh !important;
      position: fixed !important;
      background: rgba(17, 17, 24, 0.6) !important;
      backdrop-filter: blur(4px) !important;
      top: -1px !important;
      left: 9px !important;
      border: none !important;
    }

    &:after {
      border: none !important;
    }

    .p-overlaypanel-content {
      z-index: 9999;
      position: relative;
      padding: 15px;
      border-radius: 15px;
      width: 100%;
    }
  }

  .ng-trigger.ng-trigger-overlayAnimation.fly-calendar.p-datepicker {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0 !important;
    background: transparent;
    display: block !important;
    align-content: start;
    justify-content: center;
    padding: 0 20px !important;
    padding-top: 100px !important;

    &:before {
      content: '' !important;
      width: 100vw !important;
      height: 100vh !important;
      position: fixed !important;
      background: rgba(17, 17, 24, 0.6) !important;
      backdrop-filter: blur(4px) !important;
      top: 0 !important;
      left: 0 !important;
      border: none !important;
    }

    &:after {
      border: none !important;
    }

    .p-datepicker-content {
      z-index: 9999;
      position: relative;
      background: white;
      padding: 15px;
      border-radius: 15px;
    }

    .p-datepicker-group-container {
      border-top-left-radius: 16px !important;
      border-top-right-radius: 16px !important;
      overflow: hidden !important;
    }

    p-header+.p-datepicker-group-container {
      border-top-left-radius: 0 !important;
      border-top-right-radius: 0 !important;
    }

    p-footer {
      background: white;
      z-index: 999;
      position: fixed;
      width: calc(100% - 40px);
      border-radius: 0 0 16px 16px;
      margin-top: -1px;

      .calendar-footer {
        border-radius: 0 0 8px 8px;
      }

      .close-button {
        padding: 0;
      }

      @media screen and (max-width: 412px) {
        width: calc(100% - 45px);
      }
    }

    .p-yearpicker,
    .p-monthpicker {
      background: white;
      z-index: 999;
      position: relative;
      margin: 0;
      border-radius: 0;
      padding: 15px;

      &+.p-footer .custom-available-times {
        display: none;
      }
    }

    .p-timepicker {
      background: white;
      z-index: 999;
      position: relative;
    }

    .p-datepicker-group {
      background: white;
      position: relative;
      z-index: 999;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
    }

    .fly-calendar .p-datepicker-header {
      background-color: #ffffff;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
      height: 48px;
    }

    @media screen and (max-width: 412px) {
      padding-left: 5px !important;
      padding-right: 40px !important;
    }
  }

  /* Safari-specific styles */
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) {
      .ng-trigger.ng-trigger-overlayAnimation.fly-calendar.p-datepicker {
        left: 10px !important;
      }
    }
  }
}

@media screen and (max-width: 500px) {
  .toolbar-1 {
    .cdk-overlay-pane:not(.auth-modal):not(.enrollment-modal) {
      max-width: 92vw;
    }
  }
}

.flight-voucher-mobile {
  .passager {
    gap: 0 !important;

    &:not(:last-child) {
      margin-bottom: 10px;
      padding-bottom: 10px;
      border-bottom: 1px solid #d5d6e2;
    }
  }

  .plane-info {
    border-radius: 20px !important;
  }

  .p-card {
    box-shadow: 0 8px 16px rgb(0 0 0 / 0.04%) !important;
    border: 1px solid #d5d6e2 !important;
    color: #22222f !important;

    .p-card-body {
      padding: 12px 17px !important;
    }
  }

  .checkout-wrapper[_ngcontent-serverApp-c777] {
    gap: 12px !important;
  }
}

.dark-text {
  color: #22222f;
}

.darker-text {
  color: #333447;
}

.text-purple {
  color: #5a60b0 !important;
}

.bg-white {
  background-color: #fff !important;

  .p-card.p-component {
    background-color: #fff !important;
  }
}

.mat-autocomplete-panel {
  width: 350px !important;

  @media (max-width: 991px) {
    width: calc(100% - 40px) !important;
    overflow: hidden !important;
    box-shadow: none !important;
  }
}

@media (max-width: 991px) {
  ::ng-deep .custom-modal-content {
    width: 100% !important;
  }

  ::ng-deep .fly-panel-class {
    box-shadow: none !important;
    border: none;
  }

  .mat-autocomplete-visible {
    border: none !important;
  }
}

@media (max-width: 600px) {
  .blur-layout {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(2px);
    z-index: 1000;
    background: rgba(17, 17, 24, 0.5);
  }

  .cdk-overlay-pane {
    margin-top: 0 !important;
    margin-right: 0 !important;
    width: 100% !important;
    align-self: center;
    border-radius: 8px;
    border: 1px;
  }

  .cdk-global-overlay-wrapper {
    justify-content: center !important;
  }
}

.custom-modal {
  background: rgba(17, 17, 24, 0.6) !important;
  backdrop-filter: blur(8px) !important;
  position: fixed;
  top: 0;
  width: 100vw;
  left: 0;
  height: 100vh;
  z-index: 999;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: start;

  .custom-modal-content {
    background: #ececf2;
    min-height: 70%;
    width: 100%;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .custom-modal-header {
      position: relative;
      background: white;
      border-radius: 8px 8px 0 0;
      padding: 13px 10px 5px;

      margin-bottom: 10px;

      .close-modal-btn {
        position: absolute;
        right: 20px;
        top: 12px;
        cursor: pointer;
        color: #22222f;
        background: transparent;
        border: none;
      }

      .custom-modal-title {
        font-size: 20px;
        font-weight: 600;
        color: #22222f;
        text-align: center;
      }
    }

    .custom-modal-body {
      overflow: auto;
      background: white;
      border-radius: 0 0 8px 8px;
      padding: 20px;
      flex: 1;
    }

    .custom-modal-footer {
      border-top: 1px solid #ececf2;
      background: white;
      border-radius: 0 0 8px 8px;
      padding: 20px;
      display: flex;
      justify-content: flex-end;
      gap: 10px;

      .p-button {
        background: $primary-color !important;
        width: 100%;
        font-family: Asap, 'sans-serif';
        font-weight: 600;
      }
    }
  }

  .custom-modal-content-minimn-size {
    background: #ececf2;
    //max-width: 500px;
    height: 500px;
    max-height: 500px;
    width: 100%;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .custom-modal-header {
      position: relative;
      background: white;
      border-radius: 8px 8px 0 0;
      padding: 13px 10px 5px;
      margin-bottom: 10px;
      border-bottom: 1px solid #d5d6e2;

      .close-modal-btn {
        position: absolute;
        right: 20px;
        top: 12px;
        cursor: pointer;
        color: #22222f;
        background: transparent;
        border: none;
      }

      .custom-modal-title {
        font-size: 20px;
        font-weight: 600;
        color: #22222f;
        text-align: center;
      }
    }

    .custom-modal-body {
      overflow: auto;
      background: white;
      border-radius: 0 0 8px 8px;
      padding: 20px;
      flex: 1;

      .search-input-paddings {
        padding: 8px 8px 8px 16px;
      }

      .mat-form-field.pb-0 .mat-form-field-wrapper {
        padding-bottom: 0;
      }
    }

    .custom-modal-footer {
      border-top: 1px solid #d5d6e2;
      background: white;
      border-radius: 0 0 8px 8px;
      padding: 16px;
      display: flex;
      justify-content: flex-end;
      gap: 10px;

      .p-button {
        background: $primary-color !important;
        height: 100%;
        max-height: 40px;
        width: 100%;
        font-family: Asap, 'sans-serif';
        font-weight: 600;
      }
    }
  }
}

.pointer-event-none {
  pointer-events: none !important;
}

.button-bloc-mob {
  display: flex;
  flex-direction: column;
  gap: 10px;

  .p-button {
    width: 100%;
  }

  .button-primary {
    .p-button {
      background: $primary-color !important;
      color: #f8cc50 !important;
      border: none !important;
      height: 45px;
      border-radius: 8px;
    }
  }

  .btn-color-outline {
    .p-button {
      background: transparent !important;
      color: #515478 !important;
      border: 1px solid #515478 !important;
      height: 45px;
      border-radius: 8px;

      .p-button-label {
        color: #515478 !important;
      }
    }
  }
}

@media screen and (max-width: 991px) {
  .dialog-open {
    .toolbar-1 {
      .cdk-overlay-pane {
        max-width: 100vw !important;
        width: 100vw !important;
      }
    }
  }

  .custom-overlay-container {
    .cdk-overlay-pane {
      margin: auto !important;
    }
  }
}

.custom-form-modal {
  width: 100%;

  .mat-form-field {
    width: 100%;
  }

  .mat-form-field-appearance-fill.mat-form-field-flex {
    background: transparent;
  }

  .mat-form-field-label-wrapper {
    display: none;
  }

  .custom-select-input {
    height: 46px;
    border: 1px solid $primary-color;
    padding: 0 9px;
    max-width: 100%;
    border-radius: 8px;
    overflow: hidden;
    position: relative;

    .mat-icon {
      position: absolute;
      left: 12px;
      top: 9px;
    }

    input {
      color: $primary-color;
      height: 100%;
      max-width: calc(100% - 35px);
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .mat-form-field-infix {
    width: 100%;
    padding: 0 !important;
    border: none !important;

    @media (min-width: 992px) {
      margin-top: 5px;
      padding: 0;
      border-top: 0;
    }
  }

  ul li {
    list-style: none;
    display: flex;
    align-items: center;
    position: relative;

    .icon-part {
      width: 30px;
      display: flex;
      align-items: center;
    }

    .location-part {
      width: calc(100% - 30px);
      color: #22222f;
    }

    &.selected-location {
      background: rgba(59, 114, 176, 0.37);
      color: white !important;
      border-radius: 5px;

      .location-part {
        color: white;
      }
    }
  }

  .custom-location-list {
    .custom-icon-location.mat-icon {
      height: 24px;
      width: 24px;
      overflow: visible;

      svg {
        height: 24px;
        width: 24px;
        position: absolute;
        left: 6px;
      }
    }
  }

  .input-header {
    background: #ebebf1;
    padding-bottom: 10px;
    color: #416dde;
  }

  .mat-form-field-underline {
    display: none;
  }
}

.border-gray {
  border: 1px solid #d5d6e2 !important;
}

.airport-element {
  margin-bottom: 0;

  .fly-parent {
    &:hover {
      opacity: 0.5;

      &~.fly-children {
        opacity: 0.5;
      }
    }
  }

  &.selected-airplane {
    .fly-parent {
      background-color: $primary-color;
      color: white;
      border: none;

      .mat-icon svg {
        path {
          fill: white !important;
        }
      }
    }

    .fly-children {
      background: rgba(59, 114, 176, 0.37);
      color: white;
      border: none;

      .mat-icon svg {
        path {
          fill: white !important;
        }
      }
    }
  }

  .fly-children {
    &:hover {
      opacity: 0.5;
    }

    &.selected-airport {
      background-color: $primary-color;
      color: white;
      border: none;

      .mat-icon svg {
        path {
          fill: white !important;
        }
      }
    }
  }
}

.fake-input-color {
  color: $primary-color;
}

.cdk-overlay-pane.user-menu-dialog {
  @media (max-width: 991px) {
    width: 260px !important;
    max-width: 260px !important;
  }
}

.user-menu-dialog {
  border: 1px solid #d5d6e2;
  border-radius: 16px;
  min-width: 276px;

  @media (max-width: 991px) {
    border: 0;
  }

  * {
    font-size: 16px;
    font-family: Inter, sans-serif;
    color: #213a83 !important;
  }

  .mat-mdc-dialog-container .mdc-dialog__surface {
    border-radius: 16px;

    @media (max-width: 991px) {
      width: 260px;
    }
  }
}

.box-widget {
  border: 1px solid #d5d6e2;
  border-radius: 16px;
  padding: 30px;

  &.hotel-card-container {
    padding: 25px;
  }

  &.inner-filter {
    padding: 20px;
  }
}

.map-skeleton {
  .p-skeleton {
    position: absolute;
    left: 30px;
    top: 30px;
  }
}

.text-purple {
  color: #5a60b0;

  @media (max-width: 767px) {
    font-family: Inter;
    font-size: 20px;
    font-weight: 700;
    line-height: 20px;
  }
}

.text-gray {
  color: #8588ab !important;
}

.text-black {
  color: #393a53;
}

.font-asap {
  font-family: Asap, sans-serif !important;
}

.bundled-search {
  .mat-form-field {
    svg {
      path {
        fill: #8588ab;
      }
    }

    &:last-of-type {
      svg {
        path {
          stroke: #8588ab;
          fill: transparent;
        }
      }
    }
  }
}

.resource-listing-footer {
  .footer {
    width: 100% !important;
  }
}

.lh {
  line-height: 24px;
}

.top-minus-4 {
  top: -4px;
  position: relative;
}

.text-yellow {
  color: #f8cc50 !important;
}

.price-field {
  .mdc-slider {
    width: 93%;
    margin: 5px auto -15px;
    display: block;

    .mdc-slider__track,
    .mdc-slider__track--active,
    .mdc-slider__track--inactive {
      height: 3px;
    }

    .mdc-slider__track {
      position: relative;

      &:after {
        content: '';
        position: absolute;
        right: 0;
        top: calc(50% - 3.5px);
        border-radius: 50%;
        background: #d9d9d9;
        height: 7px;
        width: 6.56px;
      }
    }

    .mdc-slider__track--inactive {
      background: #d9d9d9;
      opacity: 1;
    }

    .mdc-slider__track--active_fill {
      border: 4px solid $primary-color;
      background: $primary-color;
    }

    .mdc-slider__thumb-knob {
      background-color: white;
      border-color: $primary-color;
      border-width: 3px;
      width: 23.44px;
      height: 25px;
      top: calc(50% - 4px);
    }

    .mdc-slider__thumb--focused .mdc-slider__thumb-knob {
      background: $primary-color;
      border-color: $primary-color;
    }
  }
}

.filter-select .p-dropdown,
.filter-input {
  width: 100%;
  height: 48px;
  border-radius: 16px;
  border: 1px solid #d5d6e2;

  .p-placeholder {
    padding: 8px 15px;
  }
}

.filter-input {
  padding: 8px 15px;
}

.p-progressbar {
  border-radius: 10px;
  height: 8px;
  background: #d5d6e2;

  .p-progressbar-value {
    border-radius: 10px;
    background: #416dde;
  }
}

.reservation-date {
  .p-progressbar {
    .p-progressbar-value {
      background: #416dde;
    }
  }

  .mirror {
    .p-progressbar {
      transform: scale(-1);
    }
  }
}

.map-hotel-card {
  app-hotel-card {
    width: 100%;

    .hotel-card-container {
      width: 100%;
      margin-bottom: unset;

      .hotel-info-container {
        width: 100%;
      }
    }
  }
}

.maps-content {
  margin-right: 15px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.skeleton-header-box-container {
  width: 80%;
  margin: -50px auto 0;
  position: relative;
  box-shadow: 0 8px 16px 0 #00000014;
}

.text-green {
  color: #3caf41 !important;
}

.support-chat-dialog {
  border: 1px solid #d5d6e2;
  border-radius: 1rem;

  * {
    font-size: 1rem;
    font-family: 'Avenir';
  }

  .mat-mdc-dialog-container .mdc-dialog__surface {
    border-radius: 1rem;
  }

  @media (max-height: 666px) {
    max-height: 500px;
  }
}

.cdk-global-scrollblock {
  top: 0px !important;
}

.regulation-card {
  @media (max-width: 991px) {
    display: none;
  }
}

.service-info-item {
  .info-details-content {
    @media (max-width: 991px) {
      font-size: 16px !important;
      font-weight: 500;
      line-height: 24px;
    }
  }
}

@media (max-width: 991px) {
  .toolbar-1 .cdk-overlay-pane:has(.custom-made-select) {
    width: unset !important;

    .mat-select-panel-wrap {
      justify-content: unset !important;
    }
  }

  .gallery-close-icon {
    position: absolute;
    font-size: 70px;
    z-index: 9999;
    right: 0;
    top: -20px;
    color: #f8f9fa;
  }

  .hotels-dashboard-container {
    .paginator {
      justify-content: center !important;
      margin-bottom: 47px;

      .p-paginator>button,
      span.p-paginator-pages>button.p-paginator-page {
        height: 40px;
        width: 40px !important;
        border-radius: 0 !important;
        min-width: 40px;

        &.p-highlight {
          border-radius: 0 !important;
        }
      }

      .p-paginator-element,
      .hotels-dashboard-container .p-paginator-pages button,
      .hotels-dashboard-container .p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
        border-radius: 0 !important;
        font-weight: 500;
        font-family: 'Inter', serif;

        &.p-paginator-first {
          border-top-left-radius: 4px !important;
          border-bottom-left-radius: 4px !important;
        }

        &.p-paginator-last {
          border-top-right-radius: 4px !important;
          border-bottom-right-radius: 4px !important;
        }
      }
    }
  }

  .mobile-map {
    max-width: 100vw !important;
    height: 100%;

    .mat-mdc-dialog-container .mdc-dialog__surface {
      border-radius: 0 !important;
      border: 0 !important;
    }

    .wrapper {
      width: 100%;
      left: 0;
      top: 0;
      height: 100%;

      .main-maps-container {
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
        max-height: 100dvh !important;

        .map-template {
          height: 100dvh !important;
        }
      }
    }
  }
}

.search-filter-dialog,
.sort-filter-dialog,
.changes-filter-dialog {
  & .mat-mdc-dialog-container .mdc-dialog__surface {
    border-radius: 1rem;
    width: calc(100% - 3rem);
    max-width: calc(100% - 3rem);
    margin: 0 auto;
    border: 1px solid #d5d6e2;
    border-radius: 1rem;
  }
}

.no-scroll {
  overflow: hidden !important;
}

mat-progress-bar {
  --mdc-linear-progress-active-indicator-color: #416dde;
  --mdc-linear-progress-track-height: 8px;

  border-radius: 10px;
}

@media screen and (max-width: 991px) {

  .sb-circles-dark .sb-wrapper .sb-icon,
  .sb-circles-light .sb-wrapper .sb-icon {
    width: 2rem !important;
    min-width: auto !important;
    min-height: 2em !important;
  }
}

.custom-tooltip {
  padding: 20px !important;
}

.mat-mdc-tooltip {
  --mdc-plain-tooltip-supporting-text-size: 20px;
  --mdc-plain-tooltip-container-shape: 10px;
}

.no-margin {
  margin-top: 0px !important;
}

.mt-285 {
  margin-top: 285px !important;
}

.mt-510 {
  margin-top: 425px !important;
}

.mt-475 {
  margin-top: 475px !important
}

.search__title_wrap {
  width: 100%;
  font-size: 20x;
  word-wrap: break-word;
  overflow-wrap: break-word;
  margin-bottom: 15px;
  z-index: 4;
  font-family: Roboto;
  font-weight: 700;
  line-height: 23.44px;
  letter-spacing: 0.04em;
  color: white;
  text-shadow: 0px 4px 4px 0px #00000040;
  padding-left: 5px;
  &.restaurant{
    font-size: 3rem;
    text-align: center;
    margin-bottom: 35px;
  }
}